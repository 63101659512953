import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnDestroy {

  destroyed$: Subject<any>;

  constructor() {
    this.destroyed$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next();
      this.destroyed$.complete();
    }
  }

}
