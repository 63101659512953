import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PaymentFormService } from 'src/app/modules/order/shared/payment-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-business-name-field',
  templateUrl: './business-name-field.component.html',
  styleUrls: ['./business-name-field.component.scss'],
})
export class BusinessNameFieldComponent implements OnInit {
  @Input() tabIndex: number;
  loading = false;
  form: FormGroup;

  item_count = 6;

  companiesList: any[] = [];
  closeMenu: boolean = false;

  constructor(private paymentFormService: PaymentFormService, private http: HttpClient) {
    this.form = this.paymentFormService.form;
  }

  ngOnInit(): void {
    // console.log(this.form.getRawValue());

    this.searchControl.valueChanges
      .pipe(
        debounceTime(1000),
        switchMap(value => {
          if (this.tabIndex !== 0) {
            if (value.trim() == '') {
              this.companiesList = [];
              this.searchControl.patchValue('', { emitEvent: false, onlySelf: true });
              return this.companiesList;
            } else {
              const params = new HttpParams()
                .set('query', value)
                .set('item_count', this.item_count.toString())
                .set('endpoint', '');

              return this.http.get(environment.apiUrl + '/subscription/companies-search', {
                params,
              });
            }
          } else {
            return [];
          }
        })
      )
      .subscribe(
        (data: any) => {
          if (this.tabIndex !== 0) {
            if (data.error) {
              this.companiesList = [];
            } else {
              this.companiesList = data.data.items;
            }
          } else {
            return null;
          }
        },
        err => {
          if (err?.status === 500) {
            this.closeMenu = true;
          }
        }
      );
  }

  get searchControl() {
    return this.form.get('business_name');
  }

  optionSelect(item) {
    this.searchControl.patchValue(item.title, { emitEvent: false, onlySelf: true });
    // console.log(this.form);
    const company_number = item.company_number;
    this.form
      .get('company_number')
      .patchValue(company_number, { emitEvent: false, onlySelf: true });
  }
}
