import { Component, OnInit } from '@angular/core';
import { GuidedTour, GuidedTourService, TourStep } from 'ngx-guided-tour';
import allGuides from "../shared/guides/index";
import { DevGuidedTourService } from '../shared/services/dev-guided-tour.service';
@Component({
  selector: 'app-dev-guided-tour',
  templateUrl: './dev-guided-tour.component.html',
  styleUrls: ['./dev-guided-tour.component.scss']
})
export class DevGuidedTourComponent implements OnInit {

  constructor(
    private guidedTourService: GuidedTourService,
    private devGuidedTourService: DevGuidedTourService
  ) {
    this.devGuidedTourService.setGuides(allGuides);
  }

  ngOnInit(): void {
    this.devGuidedTourService.initialize();
  }

}
  