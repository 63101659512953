import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevGuidedTourComponent } from './components/dev-guided-tour.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [DevGuidedTourComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [DevGuidedTourComponent]
})
export class DevGuidedTourModule { }
