import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorize-direct-debit',
  templateUrl: './authorize-direct-debit.component.html',
  styleUrls: ['./authorize-direct-debit.component.scss']
})
export class AuthorizeDirectDebitComponent implements OnInit {
  @Input() modal;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.document.body.classList.add('authorize-direct-debit-modal');
  }

  ngOnDestroy() {
    this.document.body.classList.remove('authorize-direct-debit-modal');
  }
}
