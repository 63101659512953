import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageModalComponent } from 'src/app/shared/components/message-modal/message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  constructor(
    private router: Router,
    // public dialogService: DialogService,
  ) { }

  showPopup(type, content?, modalCss?, cancelButtonState?, buttonText?: string) {
    /*        const dialogRef = this.dialog.open(
                MessageModalComponent,
                {
                    panelClass: modalCss ? modalCss : '',
                    data: content,
                    autoFocus: false
                });
            dialogRef.componentInstance.type = type;
            if(buttonText) {
                dialogRef.componentInstance.buttonText = buttonText;
            }
            if (cancelButtonState != null) {
                dialogRef.componentInstance.cancelButtonState = cancelButtonState;
            } else {
                dialogRef.componentInstance.cancelButtonState = false;
            }
            return dialogRef.afterClosed();
        */
  }
}
