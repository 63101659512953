import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app'; import 'firebase/auth';
import { CookieService } from '@gorniv/ngx-universal';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private loggedIn = new BehaviorSubject<any>(null);

    supportedPopupSignInMethods = [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ];

    constructor(
        private http: HttpClient,
        private firebaseAuth: AngularFireAuth,
        private cookieService: CookieService,
        private permissionsService: NgxPermissionsService
    ) {
        // rehydrate
        if (!this.isLoggedIn()) {
            this.permissionsService.loadPermissions(['GUEST']);

            if (this.cookieService.get('user')) {
                this.startSession(JSON.parse(this.cookieService.get('user')));
            }
        }
        
        const self = this;

        if (this.isLoggedIn()) {
            // token verify
            const onAuthStateChanged: any = this.firebaseAuth.onAuthStateChanged(function(user) {
                if (!user) {
                    self.logOut();
                } else {
                    self.startSession(user.toJSON());
                }
            }).then((unsubscribe) => {
                unsubscribe();
            });
        }
    }

    registerWithMail = async (formValue: { fullName: string, email: string, password: string }) => {
        try {
            const signResult = await this.firebaseAuth.createUserWithEmailAndPassword(formValue.email, formValue.password);
            const user = signResult.user;

            await user.updateProfile({
                displayName: formValue.fullName,
            });

            user.sendEmailVerification();

            return {
                success: true,
                message: 'Your account was successfully created! Now you need to verify your e-mail address, please go check your inbox.'
            };
        } catch (err) {
            let message = `Something went wrong, we couldn't create your account. Please try again.`;
            if (err.code === 'auth/email-already-in-use') {
                message = err.message;
            }
            return {
                success: false,
                message
            };
        }

    }

    loginWithMail = async (formValue: { email: string, password: string }) => {
        try {
            const result = await this.firebaseAuth.signInWithEmailAndPassword(formValue.email, formValue.password);
            const user = result.user;
            if (user.emailVerified) {
                this.startSession(user.toJSON());

                return {
                    success: true,
                    message: 'Welcome'
                };
            } else {
                return {
                    success: false,
                    message: 'Please verify your email address'
                };
            }
        } catch (err) {
            return {
                success: false,
                message: `Something went wrong, The email address or password is incorrect. Please try again.`
            };
        }
    }

    loginWithGoogle = async () => {
        try {
            const signResult = await this.firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
            const user = signResult.user;
            // zoho entegre edilcek...
            // if (signResult.additionalUserInfo.isNewUser) {
            //     await this.createZohoCustomerUser(user);
            // }
            this.startSession(user.toJSON());
            return { success: true };
        } catch (err) {
            return {};
        }
    }

    loginWithFacebook = async () => {
        try {
            const signResult = await this.firebaseAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
            const user = signResult.user;
            // zoho entegre edilcek...
            // if (signResult.additionalUserInfo.isNewUser) {
            //     await this.createZohoCustomerUser(user);
            // }
            this.startSession(user.toJSON());
            return { success: true };
        } catch (err) {
            if (err.email && err.credential && err.code === 'auth/account-exists-with-different-credential') {
                const providers = await firebase.auth().fetchSignInMethodsForEmail(err.email);
                const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));
                // Test: Could this happen with email link then trying social provider?
                if (!firstPopupProviderMethod) {
                    return { success: false, credential: null };
                } else {
                    return { success: false, credential: err.credential };
                }
            } else {
                return {};
            }
        }
    }

    loginWithGoogleandLinkWith = async (credential) => {
        try {
            await this.firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
            const user = firebase.auth().currentUser;

            const providers = await firebase.auth().fetchSignInMethodsForEmail(user.email);
            const firstPopupProviderMethod = providers.find(p => this.supportedPopupSignInMethods.includes(p));

            // Test: Could this happen with email link then trying social provider?
            if (!firstPopupProviderMethod || firstPopupProviderMethod !== firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
                throw new Error(`Your account is linked to a provider that isn't supported.`);
            }

            await user.linkWithCredential(credential);
            this.startSession(user.toJSON());

            return { success: true };
        } catch (error) {
            return {};
        }

    }

    startSession(userInfo: any) {
        this.cookieService.put('user', JSON.stringify(userInfo));
        this.permissionsService.loadPermissions(["USER"]);
        this.sendLoginInfo(userInfo);
    }

    sendLoginInfo(username) {
        this.loggedIn.next(username);
    }

    getLoginInfo(): Observable<any> {
        return this.loggedIn.asObservable();
    }

    getLoginInfoValue() {
        return this.loggedIn.value;
    }

    isLoggedIn(): boolean {
        return this.loggedIn.value ? true : null;
    }

    resetPasswordWithMail = async (formValue: { email: string }) => {
        try {
            await this.firebaseAuth.sendPasswordResetEmail(formValue.email);
            return {
                success: true,
                message: 'The reset link has been successfully sent to your mail, please go check your inbox.'
            };
        } catch (err) {
            return {
                success: false,
                message: `Email not found.`
            };
        }
    }

    resetPassword = async (code, formValue: { password: string }) => {
        try {
            await this.firebaseAuth.confirmPasswordReset(code, formValue.password);
            return {
                success: true,
                message: 'Your password was successfully reset.'
            };
        } catch (err) {
            return {
                success: false,
                message: `Something went wrong.`
            };
        }
    }

    verifyEmail = async (verifyCode) => {
        try {
            await this.firebaseAuth.applyActionCode(verifyCode);
            return {
                success: true,
                message: 'Your email was successfully verified.'
            };
        } catch (err) {
            return {
                success: false,
                message: `Something went wrong.`
            };
        }
    }

    logOut = async () => {
        try {
            await this.firebaseAuth.signOut();
            this.sendLoginInfo(null);
            this.cookieService.remove('user');
            this.permissionsService.loadPermissions(['GUEST']);

            return {
                success: true,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    }

    signInWithEmailLink(
        email: string,
        redirectUrl,
    ) {
        this.cookieService.put('email', email);
        this.cookieService.put('signInWithEmailUrl', redirectUrl);

        return this.firebaseAuth.sendSignInLinkToEmail(email, {
            url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/auth/sign-in-with-email`,
            handleCodeInApp: true,
        });
    }
}
