import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface PaymentTypeForms {
  card: FormGroup;
  bank_account: FormGroup;
}

export interface BankAccount {
  name: string;
  last_name: string;
  account_number: number;
  branch_code: number;
}

export interface FormData {
  business_name: string;
  company_number: string;
  email: string;
  payment_type: string;
  billing: {
    zip_code?: string;
    address1: string;
    address2: string;
    city?: string;
    country?: string;
  };
  payment_method: {
    gateway: string;
    type: string;
    data: BankAccount | any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PaymentFormService {
  mainFormDefaultValues: any = {
    email: '',
    business_name : '',
    company_number : '',
    payment_type: 'bank_account',
  };

  paymentTypeFormDefaultValues = {
    bank_account: {
      name: '',
      last_name: '',
      account_number: '',
      branch_code: '',
      term_of_cond: '',
      zip_code: '',
      address1: '',
      address2: '',
      city: '',
      country: '',
    },
    card: {
      name: '',
      last_name: '',
      card_number: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
    },
  };

  form: FormGroup;
  paymentTypeForms: PaymentTypeForms;

  private _valid: boolean = false;
  get valid() {
    return this._valid;
  }
  set valid(value: boolean) {
    this._valid = value;
  }

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    this.initForms();
  }

  initForms() {
    this.createMainForm();
    this.createPaymentTypeForms();

    this.form.valueChanges.subscribe(data => this.validController());
    this.paymentTypeForms.bank_account.valueChanges.subscribe(data => this.validController());
    this.paymentTypeForms.card.valueChanges.subscribe(data => this.validController());
  }

  createMainForm() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      business_name: new FormControl('', [Validators.required]),
      company_number: new FormControl(''),
      payment_type: new FormControl(),
    });

    this.form.setValue(this.mainFormDefaultValues);
  }

  createPaymentTypeForms() {
    this.paymentTypeForms = {
      card: this.formBuilder.group({
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        card_number: new FormControl('', [Validators.required]),
        exp_month: new FormControl('', [Validators.required]),
        exp_year: new FormControl('', [Validators.required]),
        cvc: new FormControl('', [Validators.required]),
      }),
      bank_account: this.formBuilder.group({
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        account_number: new FormControl('', [Validators.required]),
        branch_code: new FormControl('', [Validators.required]),
        term_of_cond: new FormControl('', [Validators.required]),
        zip_code: new FormControl('', [Validators.required]),
        address1: new FormControl('', [Validators.required]),
        address2: new FormControl('', []),
        city: new FormControl('', [Validators.required]),
        country: new FormControl('GB', [Validators.required]),
      }),
    };
  }

  markFormGroupTouched() {
    this.paymentTypeForms.bank_account.markAllAsTouched();
    this.form.markAllAsTouched();
  }

  getSelectedPaymentTypeForm(): FormGroup {
    return this.paymentTypeForms[this.form.value.payment_type];
  }

  validController() {
    if (this.form.controls.payment_type.valid) {
      if (this.form.valid && this.getSelectedPaymentTypeForm().valid) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    }
  }

  getMaxCardExpirationYears(): number[] {
    const currentYear = new Date().getFullYear();
    const cardExpirationYears = [];
    for (let year = currentYear; year <= currentYear + 30; year++) {
      cardExpirationYears.push(year);
    }

    return cardExpirationYears;
  }

  getFormData(): FormData {
    const payment_type = this.form.value.payment_type;

    if (payment_type === 'bank_account') {
      const paymentTypeForm = this.paymentTypeForms.bank_account;

      return {
        ...this.form.value,
        billing: {
          zip_code: paymentTypeForm.value.zip_code,
          address1: paymentTypeForm.value.address1,
          address2: paymentTypeForm.value.address2,
          city: paymentTypeForm.value.city,
          country: paymentTypeForm.value.country,
        },
        payment_method: {
          gateway: 'gocardless',
          type: 'bank_account',
          data: {
            given_name: paymentTypeForm.value.name,
            family_name: paymentTypeForm.value.last_name,
            account_number: paymentTypeForm.value.account_number,
            branch_code: paymentTypeForm.value.branch_code,
          },
        },
      };
    } else {
      const paymentTypeForm = this.paymentTypeForms.card;

      return {
        ...this.form.value,
        billing: {
          zip_code: paymentTypeForm.value.zip_code,
          address1: paymentTypeForm.value.address1,
          address2: paymentTypeForm.value.address2,
          city: paymentTypeForm.value.city,
          country: paymentTypeForm.value.country,
        },
        payment_method: {
          gateway: '',
          type: 'card',
          data: {
            name: paymentTypeForm.value.name,
            last_name: paymentTypeForm.value.last_name,
            number: paymentTypeForm.value.card_number, // card number
            exp_month: paymentTypeForm.value.exp_month,
            exp_year: paymentTypeForm.value.exp_year,
            cvc: paymentTypeForm.value.cvc,
          },
        },
      };
    }
  }

  resetFormData() {
    this.form.reset(this.mainFormDefaultValues);

    for (const paymentType in this.paymentTypeForms) {
      this.paymentTypeForms[paymentType].reset(this.paymentTypeFormDefaultValues[paymentType]);
    }
  }
}
