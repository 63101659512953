import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './modules/core/core.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { SharedModule } from './shared/shared.module';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DevGuidedTourModule } from './modules/dev-guided-tour/dev-guided-tour.module';
import {ToastModule} from 'primeng/toast';

registerLocaleData(localeEs, 'es');

@NgModule({
  imports: [
    NgxPermissionsModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'iasyougo' }),
    TransferHttpCacheModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    DevGuidedTourModule,
    AppRoutingModule,
    NgbModule,
    ToastModule
  ],
  declarations: [
    HomePageComponent,
    Error404PageComponent,
    AppComponent,
  ]
})

export class AppModule {
}
