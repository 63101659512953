import { Injectable } from "@angular/core";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { GuidedTour, GuidedTourService } from "ngx-guided-tour";
import { DevGuidedTour } from "../../dev-guided-tour";

@Injectable({
    providedIn: 'root'
})
export class DevGuidedTourService {
    private guides: DevGuidedTour[];
    private guide: GuidedTour;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private guidedTourService: GuidedTourService
    ) {}

    setGuides(guides: DevGuidedTour[]): void {
        this.guides = guides;
    }

    getGuides(): DevGuidedTour[] {
        return this.guides;
    }

    getGuide() {
        return this.guide;
    }

    protected findGuideByUrl(url: string, queryParams: any): GuidedTour{
        let path = url.split('?')[0];

        const findGuides = this.guides.find((guide: DevGuidedTour) => guide.url.some((url) => url.path === path));
        if (findGuides) {
            const findGuideByTourId: GuidedTour = findGuides.guides.find((guide: GuidedTour) => queryParams.guide === guide.tourId);
            return findGuideByTourId;
        } else {
            return null;
        }
    }

    protected handleRouterEvents() {
        this.router.events.subscribe((routeEvent: Event) => {
            try {
                if (routeEvent instanceof NavigationEnd) {
                    if ('guide' in this.route.snapshot.queryParams) {
                        const guidedTour: GuidedTour = this.findGuideByUrl(routeEvent.url, this.route.snapshot.queryParams);
                        if (guidedTour) {
                            this.guide = guidedTour;
                            this.guidedTourService.startTour(guidedTour);
                        }
                    }
                }
            } catch (error) {
                console.error('handleRouterEvents error: ', error);
            }
        });
    }

    initialize() {
        this.handleRouterEvents();
    }
}
