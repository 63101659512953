import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'src/app/modules/core/services/message.service';
import { PaymentFormService } from 'src/app/modules/order/shared/payment-form.service';
import { AuthService } from '../../../modules/auth/shared/auth.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Component({
  selector: 'app-signin-with-email',
  templateUrl: './signin-with-email.component.html',
  styleUrls: ['./signin-with-email.component.scss'],
  providers: [DialogService]
})
export class SigninWithEmailComponent implements OnInit {
  @Input() redirectUrl: string;
  formGroup: FormGroup;
  loading = false;
  sentCode = false;
  form: FormGroup;

  constructor(
    private authService: AuthService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private paymentFormService: PaymentFormService,
  ) {
    this.form = paymentFormService.form;
  }

  ngOnInit(): void { }

  sendVerificationCode() {
    if (!this.formGroup.valid) {
      this.showAlert('Email is not valid.', 'fail');
      return;
    }

    this.loading = true;

    this.authService.signInWithEmailLink(this.formGroup.value.email, this.redirectUrl)
      .then((res) => {
        this.loading = false;
        this.sentCode = true;
      })
      .catch((error) => {
        this.loading = false;
        this.showAlert(error.message, 'fail');
      });
  }

  showAlert(content, type): any {
    const ref = this.dialogService.open(MessageModalComponent, {
      data: { type, content }
    });
  }

}
