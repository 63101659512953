import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RoutesConfig } from './configs/routes.config';
import { UtilsHelperService } from './modules/core/services/utils-helper.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';

declare const Modernizr;

@Component({
  selector: 'app-root',
  template: '<app-dev-guided-tour></app-dev-guided-tour> <router-outlet></router-outlet><p-toast></p-toast>',
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  isOnline: boolean;

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    private i18n: I18n,
    private renderer: Renderer2,
    private primengConfig: PrimeNGConfig,
    @Inject(DOCUMENT) doc: Document,
    @Inject(LOCALE_ID) locale: string,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isOnline = navigator.onLine;
      renderer.setAttribute(doc.documentElement, 'lang', locale);
    } else {
      this.isOnline = true;
    }
  }

  ngOnInit() {
    this.title.setTitle(this.i18n({ value: 'Subscription | I As You Go', id: '@@appTitle' }));

    this.onEvents();
    this.checkBrowser();
    this.primengConfig.ripple = true;
  }

  onEvents() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.urlAfterRedirects) {
          case '/':
            this.meta.updateTag({
              name: 'description',
              content: this.i18n({
                value:
                  'iasyougo is a team of professional accountants with vast individual experience in the accounting profession. Accounting services have started to diversify from',
                id: '@@homeMetaDescription',
              }),
            });
            break;
        }
      }
    });
  }

  checkBrowser() {
    if (isPlatformBrowser(this.platformId)) {
      if (UtilsHelperService.isBrowserValid()) {
        this.checkBrowserFeatures();
      } else {
        this.messageService.add({
          severity: 'warn', summary: 'Warn', sticky: true,
          detail: this.i18n({ value: 'Change your browser', id: '@@changeBrowser' })
        });
      }
    }
  }

  checkBrowserFeatures() {
    let supported = true;
    for (const feature in Modernizr) {
      if (
        Modernizr.hasOwnProperty(feature) &&
        typeof Modernizr[feature] === 'boolean' &&
        Modernizr[feature] === false
      ) {
        supported = false;
        break;
      }
    }

    if (!supported) {
      this.messageService.add({
        severity: 'warn', summary: 'Warn', sticky: true,
        detail: this.i18n({ value: 'Update your browser', id: '@@updateBrowser' })
      });
    }

    return supported;
  }
}
