import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxExampleLibraryModule } from '@ismaestro/ngx-example-library';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { LoadingPlaceholderComponent } from './components/loading-placeholder/loading-placeholder.component';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BaseComponent } from './components/base/base.component';
import { AlphabetOnlyDirective } from './directives/alphabet-only.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { UserToolbarComponent } from './components/user-toolbar/user-toolbar.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { GuestToolbarComponent } from './components/guest-toolbar/guest-toolbar.component';
import { SigninWithEmailComponent } from './components/signin-with-email/signin-with-email.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SwiperModule } from 'swiper/angular';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { TermsAndConditionComponent } from './components/terms-and-condition/terms-and-condition.component';
import { AuthorizeDirectDebitComponent } from './components/authorize-direct-debit/authorize-direct-debit.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BusinessNameFieldComponent } from './components/business-name-field/business-name-field.component';

@NgModule({
  imports: [
    GuidedTourModule,
    SwiperModule,
    ProgressSpinnerModule,
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    NgxExampleLibraryModule,
    NgxScrollToFirstInvalidModule,
    LazyLoadImageModule,
    FormsModule,
    SidebarModule,
    ButtonModule,
    DynamicDialogModule,
    NgxPermissionsModule.forChild(),
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    LoadingPlaceholderComponent,
    CapitalizeFirstPipe,
    MainLayoutComponent,
    MessageModalComponent,
    LoadingComponent,
    BaseComponent,
    AlphabetOnlyDirective,
    NumbersOnlyDirective,
    UserToolbarComponent,
    GuestToolbarComponent,
    SigninWithEmailComponent,
    TermsAndConditionComponent,
    AuthorizeDirectDebitComponent,
    BusinessNameFieldComponent,
  ],
  exports: [
    ProgressSpinnerModule,
    GuidedTourModule,
    SwiperModule,
    CommonModule,
    FlexLayoutModule,
    NgxExampleLibraryModule,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    NgxScrollToFirstInvalidModule,
    LoadingPlaceholderComponent,
    CapitalizeFirstPipe,
    LazyLoadImageModule,
    NumbersOnlyDirective,
    AlphabetOnlyDirective,
    UserToolbarComponent,
    GuestToolbarComponent,
    SigninWithEmailComponent,
    TermsAndConditionComponent,
    AuthorizeDirectDebitComponent,
    BusinessNameFieldComponent,
  ],
  providers: [GuidedTourService],
  entryComponents: [MessageModalComponent],
})
export class SharedModule {}
