import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

export let ROUTES_CONFIG = new InjectionToken('routes.config');

const basePaths = {
  home: '',
  auth: 'auth',
  user: 'user',
  order: 'order',
  orderconfirm: 'checkout',
};

const routesNames = {
  home: '',
  auth: {
    basePath: basePaths.auth,
    signIn: 'sign-in',
    signUp: 'sign-up',
    logOut: 'logout',
  },
  user: {
    basePath: basePaths.user,
    account: 'account',
  },
  error404: '404',
  order: {
    basePath: basePaths.order,
  },
  orderconfirm: {
    basePath: basePaths.orderconfirm,
  },
};

export const RoutesConfig: {routesNames: any, routes: any} = {
  routesNames,
  routes: {
    home: `/${routesNames.home}`,
    auth: {
      signIn: `/${routesNames.auth.basePath}/${routesNames.auth.signIn}`,
      signUp: `/${routesNames.auth.basePath}/${routesNames.auth.signUp}`,
      logOut: `/${routesNames.auth.basePath}/${routesNames.auth.logOut}`,
    },
    user: {
      account: `/${routesNames.user.basePath}/${routesNames.user.account}`
    },
    error404: `/${routesNames.error404}`,
  }
};


export function getDashboard() {
  return environment.dashboardUrl;
}

export function getDashboardLogin() {
  return `${getDashboard()}/auth/sign-in`;
}

export function getDashboardLoginWithToken(access_token: any) {
  return `${getDashboardLogin()}/?access_token=${access_token}&new_user=true`;
}
