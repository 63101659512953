import { Component, OnInit } from '@angular/core';
import { RoutesConfig } from 'src/app/configs/routes.config';

@Component({
  selector: 'app-guest-toolbar',
  templateUrl: './guest-toolbar.component.html',
  styleUrls: ['./guest-toolbar.component.scss']
})
export class GuestToolbarComponent implements OnInit {
  authRoute = RoutesConfig.routes.auth;

  constructor() { }

  ngOnInit(): void {
  }

}
