import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  type: string;
  data: any;
  title = '';
  content = '';
  design;
  buttonText: string;
  cancelButtonState: boolean;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.config.showHeader = false;
    this.config.width = window.innerWidth > 768 ? '500px' : 'auto';
    this.config.contentStyle = {
      padding: 0,
      'max-width': '80vw',
      'border-radius': '5px'
    };
  }

  ngOnInit(): void {
    if (this.config.data.type === 'fail') {
      this.design = { color: 'danger', icon: 'close' };
      this.title = 'Error';
    }
    if (this.config.data.type === 'success') {
      this.design = { color: 'success', icon: 'check' };
      this.title = 'Success';
    }
    if (this.config.data.type === 'delete') {
      this.design = { color: 'secondary', icon: 'delete' };
      this.title = 'Delete This?';
    }
    if (this.config.data.type === 'warning') {
      this.design = { color: 'warning', icon: 'warning' };
      this.title = 'Are you sure?';
    }
    if (this.config.data.type === 'conflict') {
      this.design = { color: 'warning', icon: 'warning' };
      this.title = 'Account already exist for provided e-mail.';
    }
    this.data = this.title;
  }

  close(): any {
    this.ref.destroy();
  }

  cancel() {
    this.ref.close();
  }

}
