import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404PageComponent } from './pages/error404-page/error404-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { RoutesConfig } from './configs/routes.config';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';

const routesNames = RoutesConfig.routesNames;

const routes: Routes = [
  /*{
    path: routesNames.home,
    component: MainLayoutComponent,
    children: [{
      path: '',
      component: HomePageComponent,
      pathMatch: 'full'
    }]
  },*/
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
      },
    ],
  },
  {
    path: routesNames.auth.basePath,
    component: MainLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    }]
  },
  {
    path: routesNames.user.basePath,
    component: MainLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
    }]
  },


  { path: routesNames.error404, component: Error404PageComponent },

  { path: 'en', redirectTo: '' }, // because english language is the default one

  // otherwise redirect to 404
  { path: '**', redirectTo: RoutesConfig.routes.error404 }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
