import { enableProdMode, MissingTranslationStrategy } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppBrowserModule, {
    missingTranslation: MissingTranslationStrategy.Error
  }).then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      //navigator.serviceWorker.register('./ngsw-worker.js');
      
      // unregister service worker
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
         for(let registration of registrations) {
          registration.unregister()
        }
      });
    }
  }).catch(err => console.log(err));
});
