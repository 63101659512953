import { Component, OnInit } from '@angular/core';
import { RoutesConfig } from 'src/app/configs/routes.config';
import { AuthService } from 'src/app/modules/auth/shared/auth.service';
@Component({
  selector: 'app-user-toolbar',
  templateUrl: './user-toolbar.component.html',
  styleUrls: ['./user-toolbar.component.scss']
})
export class UserToolbarComponent implements OnInit {
  appRoutes = RoutesConfig.routes;
  user: any;

  constructor(
    protected authService: AuthService
  ) { }
  
  ngOnInit(): void {
    this.user = this.authService.getLoginInfoValue();
  }

}
