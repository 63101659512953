import { GuidedTour, TourStep } from "ngx-guided-tour";
import { DevGuidedTour } from "../../../dev-guided-tour";

const guides: DevGuidedTour[] = [
    {
        url: [
          {
            path: '/auth/sign-in',
          }
        ],
        guides: [
            {
               tourId: 'sign-in-test',
               steps: [
                   {
                       selector: '.signin-form',
                       content: 'Sign in test'
                   },
               ],
            },
        ],
    },
];

export default guides;