// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyCEwaDwxgwe4bXUlBQGeit6Cumsx6-mShM",
//     authDomain: "iasyougo-test.firebaseapp.com",
//     projectId: "iasyougo-test",
//     storageBucket: "iasyougo-test.appspot.com",
//     messagingSenderId: "806751313486",
//     appId: "1:806751313486:web:d656f7b099e3c6a1d36d1f",
//     measurementId: "G-8V2V91SBY2",
//   },
//   dashboardUrl: 'https://iasyougo-portal-test.web.app',
//   apiUrl: 'https://us-central1-iasyougo-test.cloudfunctions.net/api',
//   //apiUrl: 'http://localhost:5001/iasyougo-eef16/us-central1/api',
// };


// CAN CHANGE BACK TO OLD ENV
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDGH26ugcSqiHJZSt_YoW-dO1hOkzGRtR8",
    authDomain: "iasyougo-eef16.firebaseapp.com",
    projectId: "iasyougo-eef16",
    storageBucket: "iasyougo-eef16.appspot.com",
    messagingSenderId: "290801760171",
    appId: "1:290801760171:web:e1c71a2f31a5572b9144ee",
    measurementId: "G-GYNLDCZ2WK"
  },
  dashboardUrl: 'https://app.iasyougo.com',
  apiUrl: 'https://us-central1-iasyougo-eef16.cloudfunctions.net/api',
  // apiUrl: 'http://localhost:5001/iasyougo-eef16/us-central1/api',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
